<template>
	<div class="grid">
		<ProgressModal :progress="progress">
			<template #message>
				Confirm to Purchasing in progress.<br />Please wait...
			</template>
		</ProgressModal>
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		<ConfirmDialog group="positionDialog"></ConfirmDialog>
		<div class="col-12">
			<Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
		</div>
		<div class="col-12 p-fluid">
			<div class="card">
				<div
					class="card"
					style="
						background-color: #da3831;
						height: 2em;
						margin: 0 0 2em 0;
					"
				>
					<h5 style="color: white; margin-top: -10px">
						Add/Edit Planning Order
					</h5>
				</div>
				<div class="p-fluid grid formgrid">
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="planningOrderNum"
								v-bind:disabled="planningOrderNum"
							/>
							<label for="inputtext">Planning Order No</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="revisition"
								disabled
							/>
							<label for="inputtext">Rev</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="planningOrderDate"
								v-bind:disabled="planningOrderDate"
							/>
							<label for="inputtext">Planning Order Date</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="planningOrderStatus"
								disabled
							/>
							<label for="inputtext">Status</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="respondDate"
								v-bind:disabled="respondDate"
							/>
							<label for="inputtext">Must Respond Date</label>
						</span>
					</div>
					<div class="col-12 mb-4 lg:col-6 lg:mb-5">
						<span class="p-float-label">
							<InputText
								id="inputtext"
								type="text"
								v-model="respondBy"
								v-bind:disabled="respondBy"
							/>
							<label for="inputtext">Respond By</label>
						</span>
					</div>
				</div>
				<div class="p-fluid grid formgrid">
					<div class="col-6 mb-4 lg:col-1 lg:mb-5">
						<Button label="Print" @click="printPage()" :disabled="this.planningOrderStatus === 'CANCELED'"/>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 p-fluid">
			<div class="card">
				
				<div
					class="p-fluid grid formgrid"
					style="margin-top: 1.5rem; margin-bottom: 0.5rem"
				>
					<div class="col-6 mb-1 lg:col-2 lg:mb-0">
						<Button
							label="Planning Order"
							class="p-button-secondary p-button-text"
							@click="openPo"
							:disabled="this.planningOrderStatus === 'CANCELED'"
						/>
					</div>
					<div class="col-6 mb-1 lg:col-1 lg:mb-0">
						<Button
							label="Terms"
							class="p-button-secondary p-button-text"
							@click="openTerms"
							:disabled="this.planningOrderStatus === 'CANCELED'"
						/>
					</div>
				</div>

				<div class="col-12 mb-5" v-if="!this.changeTable">
					<DataTable
						:value="planningOrderTable"
						dataKey="plan_order_line_id"
						:paginator="true"
						stateStorage="session"
						:rows="10"
						v-model:filters="filters"
						v-model:selection="selectedPo"
						removableSort
						stateKey="tableSession"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						:rowsPerPageOptions="[5, 10, 25]"
						:loading="loading"
						selectionMode="multiple"
						:metaKeySelection="false"
						@rowSelect="onRowSelect"
						@rowUnselect="onRowUnselect"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll"
						:scrollable="true"
						scrollHeight="600px"
						@page="onPage($event)"
					>
						<!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
						<Column header="Line" style="width: 100px">
							<template #body="slotProps">
								{{ slotProps.index + 1 + this.page }}
							</template>
						</Column>
						<Column
							field="item_num"
							header="Item Number"
							:sortable="true"
							style="width: 150px"
							
						></Column>
						<Column
							field="item_desc"
							header="Item Description"
							:sortable="true"
							style="width: 150px"
							
						></Column>
						<Column
							field="gbpa_no"
							header="No GBPA"
							:sortable="true"
							style="width: 150px"
						>
							<template #body="slotProps">
								<p>{{ slotProps.data.gbpa_no ? slotProps.data.gbpa_no : "-" }}</p>
							</template>
						</Column>
						<Column
							field="uom"
							header="UOM"
							:sortable="true"
							style="width: 150px"
							
						>
						</Column>
						<Column
							field="plan_qty"
							header="Qty"
							:sortable="true"
							dataType="numeric"
							style="width: 150px"
							
						>
							<template #body="{ data }">
								{{ formatCurrency(data.plan_qty) }}
							</template>
						</Column>
						<!-- <Column
							field="original_quantity"
							header="Original Quantity"
							:sortable="true"
							dataType="numeric"
							style="width: 150px"
							
						>
							<template #body="{ data }">
								{{ formatCurrency(data.original_quantity) }}
							</template>
						</Column> -->
						<Column header="Rate Currency" style="width: 9%">
							<template #body="slotProps">
								<InputNumber
									id="locale-german"
									v-model="slotProps.data.rate_currency"
									:minFractionDigits="2"
									locale="de-DE"
									v-bind:disabled="
										disabledOption[slotProps.index] || (slotProps.data.gbpa_no !== null && slotProps.data.gbpa_no !== '-No Select-')
									"
								/>
							</template>
						</Column>
						<Column header="Deal Currency" style="width: 12%">
							<template #body="slotProps">
								<Dropdown
									v-model="slotProps.data.deal_currency"
									:disabled="(slotProps.data.gbpa_no !== null && slotProps.data.gbpa_no !== '-No Select-')"
									:options="currenciesList"
									optionLabel="currency_code"
									optionValue="currency_code"
									style="width: 100%"
								/>
							</template>
						</Column>
						<Column
							field="ship_to_desc"
							header="Location"
							:sortable="true"
							style="width: 150px"
							
						>
						</Column>
						<Column
							field="note_to_supplier"
							header="Note to Supplier"
							:sortable="true"
							style="width: 150px"
							
						>
						</Column>
						<Column
							field="manufacturer_description"
							header="Manufacturer"
							:sortable="true"
							style="width: 150px"
							
						>
						</Column>
						<Column header="Currency" style="width: 150px">
							<template #body="slotProps">
								<Dropdown
									v-model="slotProps.data.currency"
									:options="currenciesList"
									optionLabel="currency_code"
									optionValue="currency_code"
									v-bind:disabled="
										disabledOption[slotProps.index] || 	(slotProps.data.gbpa_no !== null && slotProps.data.gbpa_no !== '-No Select-')
									"
								/>
							</template>
						</Column>
						<Column
							field="confirm_price"
							header="Confirm Price"
							style="width: 150px"
							
						>
							<template #body="slotProps">
								<InputNumber
									id="locale-german"
									v-model="slotProps.data.confirm_price"
									:minFractionDigits="2"
									locale="de-DE"
									v-bind:disabled="
										disabledOption[slotProps.index] || (slotProps.data.gbpa_no !== null && slotProps.data.gbpa_no !== '-No Select-')
									"
								/>
							</template>
						</Column>
						<Column
							field="confirm_qty"
							header="Confirm Qty"
							style="width: 150px"
							
						>
							<template #body="slotProps">
								<InputNumber
									id="locale-german"
									v-model="slotProps.data.confirm_qty"
									:minFractionDigits="2"
									locale="de-DE"
									v-bind:disabled="
										disabledOption[slotProps.index]
									"
								/>
							</template>
						</Column>
						<Column
							field="remarks"
							header="Remark"
							style="width: 150px"
							
						>
							<template #body="slotProps">
								<InputText
									type="text"
									v-model="slotProps.data.remarks"
									v-bind:disabled="
										disabledOption[slotProps.index]
									"
								/>
							</template>
						</Column>
						<!-- <Column header="Reason for not Accept" headerStyle="min-width:20rem;">
                    <template #body="slotProps">
                        <Textarea v-model="slotProps.data.remarks3" :autoResize="true" rows="5" cols="30" />
                    </template>
                </Column> -->
						<Column
							field="name"
							header="Line Status"
							:sortable="true"
							style="width: 150px"
						>
						</Column>
					</DataTable>
				</div>

				<div class="col-12 mb-5" v-if="this.changeTable">
					<DataTable
						:value="planningOrderTable"
						dataKey="plan_order_line_id"
						:paginator="true"
						:rows="10"
						stateStorage="session"
						stateKey="tableSession"
						v-model:filters="filters"
						v-model:selection="selectedPo"
						removableSort
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						:rowsPerPageOptions="[5, 10, 25]"
						:loading="loading"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						responsiveLayout="scroll"
						:scrollable="true"
						scrollHeight="600px"
						@page="onPage($event)"
					>
						<Column header="Line" style="width: 50px">
							<template #body="slotProps">
								{{ slotProps.index + 1 + this.page }}
							</template>
						</Column>
						<!-- <Column field="plan_order_line_id" header="Line" :sortable="true" headerStyle="min-width:5rem;">
                <template #body="slotProps">
                     {{slotProps.index + 1}}
                </template></Column> -->
						<Column
							field="item_num"
							header="Item Number"
							:sortable="true"
							style="width: 150px"
						></Column>
						<Column
							field="item_desc"
							header="Item Description"
							:sortable="true"
							style="width: 150px"
						></Column>
						<Column
							header="Arrival at Site"
							:sortable="true"
							field="need_by_date"
							style="width: 150px"
						>
							<!-- <template #body="slotProps">
								{{
								new Date(slotProps.data.need_by_date).toLocaleDateString('id-ID', { day: 'numeric', month: 'short', year: 'numeric'}) }}
							</template> -->
						</Column>
						<Column
							header="Arrival at W/H"
							:sortable="true"
							field="need_by_date"
							style="width: 150px"
						>
							<!-- <template #body="slotProps">
								{{
								new Date(slotProps.data.need_by_date).toLocaleDateString('id-ID', { day: 'numeric', month: 'short', year: 'numeric'}) }}
						</template> -->
						</Column>
						<Column
							field="payment_terms"
							header="ToP"
							:sortable="true"
							style="width: 150px"
						>
							<!-- <template #body="slotProps">
                        <Dropdown v-model="slotProps.data.top" :options="isiTop" optionLabel="name" optionValue="name" placeholder="Select ToP" />
                    </template> -->
						</Column>
						<Column
							field="freight_terms"
							header="Freight"
							:sortable="true"
							style="width: 150px"
						>
							<!-- <template #body="slotProps">
                        <Dropdown v-model="slotProps.data.freight" :options="isiFreight" optionLabel="name" optionValue="name" placeholder="Select Freight" />
                    </template> -->
						</Column>

						<Column
							header="Confirm Arrival at Site"
							style="width: 150px"
						>
							<template #body="slotProps">
								<Calendar
									v-model="slotProps.data.confirm_ship_date"
									dateFormat="dd-M-yy"
									:showIcon="true"
									inputStyle="text-transform: uppercase"
									v-bind:disabled="
										disabledOption[slotProps.index]
									"
								/>
							</template>
						</Column>
						<Column
							field="fob_sp"
							header="Incoterm"
							:sortable="true"
							style="width: 150px"
						>
						</Column>
					</DataTable>
				</div>

				<div class="p-fluid grid formgrid">
					<div class="col-6 mb-2 lg:col-1 lg:mb-0">
						<Button
							type="button"
							label="Back"
							class="p-button-secondary"
							@click="back()"
							:disabled="this.planningOrderStatus === 'CANCELED'"
						/>
					</div>
					<div class="col-6 mb-2 lg:col-1 lg:mb-0">
						<Button
							type="button"
							label="Save"
							@click="save()"
							:disabled="this.button || this.planningOrderStatus === 'CANCELED'"
						/>
					</div>
					<div class="col-6 mb-2 lg:col-1 lg:mb-0">
						<Button
							type="button"
							label="Confirm"
							class="p-button-success"
							@click="confirm('T')"
							:disabled="this.button || this.planningOrderStatus === 'CANCELED'"
						/>
					</div>
					<!-- <div class="col-6 mb-2 lg:col-1 lg:mb-0">
                    <Button type="button" label="Not Accept" class="p-button-danger" @click="notAccept()"/>
                </div> -->
				</div>
			</div>
		</div>
	</div>

	<Dialog
		header="Search Po Number"
		v-model:visible="displayResponsive"
		:breakpoints="{ '960px': '75vw' }"
		:style="{ width: '75vw' }"
		:modal="true"
		stateStorage="session"
	>
		<div class="p-fluid grid formgrid" style="margin-top: 1.5rem">
			<div class="col-6 mb-2 lg:col-2 lg:mb-5">
				<span class="p-float-label">
					<AutoComplete
						v-model="popUpPo"
						:suggestions="filteredPopUpPo"
						@complete="searchPopUpPo($event)"
						:dropdown="true"
						field="segment1"
						forceSelection
					>
						<template #item="slotProps">
							<div class="country-item">
								<div class="ml-2">
									{{ slotProps.item.segment1 }}
								</div>
							</div>
						</template>
					</AutoComplete>
					<label for="inputtext">PO Number</label>
				</span>
			</div>
			<div class="col-6 mb-2 lg:col-1 lg:mb-2">
				<Button type="button" label="Search" :disabled="this.planningOrderStatus === 'CANCELED'"/>
			</div>
		</div>
		<DataTable
			:value="tablepopuppo"
			dataKey="plan_order_line_id"
			:paginator="true"
			:rows="10"
			v-model:filters="filters"
			removableSort
			paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
			:rowsPerPageOptions="[5, 10, 25]"
			:loading="loading"
			currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
			responsiveLayout="scroll"
			:scrollable="true"
			scrollHeight="flex"
		>
			<Column
				field="segment1"
				header="PO Number"
				:sortable="true"
			></Column>
			<Column
				field="vendor_name"
				header="Supplier"
				:sortable="true"
			></Column>
			<Column
				field="employeename"
				header="Buyer"
				:sortable="true"
			></Column>
			<Column field="location_code" header="Ship To"> </Column>
			<Column
				field="attribute1"
				header="PO Type"
				:sortable="true"
				style="text-align: center"
			>
			</Column>
			<Column
				field="creation_date"
				header="Creation Date"
				:sortable="true"
			>
			</Column>
			<Column field="closed_code" header="Status" :sortable="true">
			</Column>
			<Column
				field="authorization_status"
				header="Approval Status"
				:sortable="true"
				style="text-align: center"
			>
			</Column>
		</DataTable>
		<template #footer>
			<Button
				label="No"
				icon="pi pi-times"
				@click="closeResponsive"
				class="p-button-text"
				:disabled="this.planningOrderStatus === 'CANCELED'"
			/>
			<Button
				label="Yes"
				icon="pi pi-check"
				@click="submitResponsive"
				
				:disabled="this.planningOrderStatus === 'CANCELED'"
			/>
		</template>
	</Dialog>

	<Dialog
		header="Validation"
		v-model:visible="displayPilih"
		:breakpoints="{ '960px': '20vw' }"
		:style="{ width: '10vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<span>Please Select Planning Order First</span>
		</div>
	</Dialog>

	<Dialog
		header="Validation"
		v-model:visible="displayValidasi"
		:breakpoints="{ '960px': '20vw' }"
		:style="{ width: '20vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<i
				class="pi pi-exclamation-triangle mr-3"
				style="font-size: 2rem"
			/>
			<span>Are you sure "Note to Supplier" column is not empty?</span>
		</div>
	</Dialog>

	<Dialog
		header="Validation"
		v-model:visible="displayConfirm"
		:breakpoints="{ '960px': '20vw' }"
		:style="{ width: '20vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<i
				class="pi pi-exclamation-triangle mr-3"
				style="font-size: 2rem"
			/>
			<span>Confirm Arrival at Site empty</span>
		</div>
	</Dialog>

	<Dialog
		header="Validation"
		v-model:visible="displayCurrency"
		:breakpoints="{ '960px': '20vw' }"
		:style="{ width: '20vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<i
				class="pi pi-exclamation-triangle mr-3"
				style="font-size: 2rem"
			/>
			<span>Rate Currency or Deal Currency is Empty</span>
		</div>
	</Dialog>


	<Dialog
		header="Validation"
		v-model:visible="displayQuantity"
		:breakpoints="{ '960px': '20vw' }"
		:style="{ width: '20vw' }"
		:modal="true"
	>
		<div class="confirmation-content">
			<i
				class="pi pi-exclamation-triangle mr-3"
				style="font-size: 2rem"
			/>
			<span>Confirm Price or Confirm Qty Empty</span>
		</div>
	</Dialog>
</template>

<script>
/* eslint-disable */
import PlanningOrder from '../../../service/PlanningOrderService';
import CreatePoService from '../../../service/CreatePoService';
import AuthService from '../../../service/AuthService';
import SimpleCrypto from 'simple-crypto-js';
import ProgressModal from '../../components/ProgressModal.vue';
import { UrlTab } from '../../../constants/UrlTab';
import Swal from 'sweetalert2';


export default {
	name: 'planningorder-list',
	components: { ProgressModal },
	data() {
		return {
			idValue: this.$route.params.id,
			company: sessionStorage.getItem('mailToCompany'),
			idDetail: null,
			isiDetail: null,
			planningOrderNum: null,
			planningOrderDate: null,
			supplier: null,
			respondBy: null,
			revisition: null,
			planningOrderStatus: null,
			respondDate: null,
			location: null,
			isiLocation: null,
			filteredLocation: null,
			displayResponsive: false,
			changeTable: false,
			planningOrderTable: null,
			selectedPo: null,
			termsTable: null,
			poType: null,
			isiPoType: null,
			filteredPoType: null,
			selectedPoType: null,
			uom: null,
			currenciesList: null,
			isiUom: null,
			freight: null,
			isiFreight: null,
			tablepopuppo: null,
			arrivalDate: null,
			top: null,
			isiTop: null,
			incoterm: null,
			isiIncoterm: null,
			status: null,
			changeStatus: null,
			unholdStatus: null,
			changeHold: false,
			changeUnhold: false,
			popUpPo: null,
			filteredPopUpPo: null,
			poNumber: null,
			qty: null,
			submitpo: false,
			isInvalid: true,
			displayValidasi: false,
			displayPilih: false,
			displayConfirm: false,
			displayQuantity: false,
			displayCurrency:false,
			something: null,
			sender: null,
			usersenderid: null,
			vendorId: null,
			dbInstance: null,
			recipientMail: null,
			vendorname: null,
			orgIdNotif: null,
			disabledOption: [],
			button: false,
			auth: true,
			page: null,
			progress: false,
			monthNames: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			],
			monthShortNames: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
			],
			breadcrumbHome: { icon: 'pi pi-home', to: '/dashboard' },
			breadcrumbItems: [
				{ label: 'Planning Order', to: '/mgm/settings/planningorder' },
				{
					label: 'Detail Planning Order',
					to: this.$route.path,
				},
			],
		};
	},
	created() {
		const secretKey = 'some-unique-key';
		const simpleCrypto = new SimpleCrypto(secretKey);
		this.recipient = simpleCrypto.decrypt(
			sessionStorage.getItem('dropdownCompany')
		);
		if (this.idValue != 0) {
			sessionStorage.setItem('printPoId', this.idValue);
			AuthService.whoAmi().then((response) => {
				this.sender = response.data.data.bioemailactive;
				// this.dbInstance = response.data.data.bioaddress;
				this.usersenderid = response.data.data.id;
				if (response.status === 200) {
					const payload = {
						idValue: this.idValue,
						vendorId: sessionStorage.vendorId,
						leveltenant: sessionStorage.leveltenant,
					};
					this.respondBy = response.data.data.userid;
					PlanningOrder.getIdDetail(payload).then((response) => {
						if (response.data.status === 202) {
							this.$router.push('/dashboard');
						}
						this.isiDetail = response.data.data;
						this.planningOrderNum =
							response.data.data[0].plan_order_num;
						this.respondDate = this.formatDateHeader(
							new Date(response.data.data[0].response_date)
						)[0];
						this.supplier = response.data.data[0].vendor_name;
						// this.respondBy = response.data.data[0].response_by
						// this.revisition = response.data.data[0].revisition;
						if (response.data.data[0].revisition != null) {
							this.revisition = response.data.data[0].revisition;
						} else {
							this.revisition = 0;
						}
						this.planningOrderStatus = response.data.data[0].status;
						if (response.data.data[0].status === 'HOLD') {
							this.changeHold = true;
							this.changeUnhold = false;
							this.submitpo = false;
						}
						if (response.data.data[0].status !== 'HOLD') {
							this.changeHold = false;
							this.changeUnhold = true;
							this.submitpo = true;
						}
						this.planningOrderDate = this.formatDateHeader(
							new Date(response.data.data[0].plan_order_date)
						)[0];
						if (this.planningOrderNum !== null) {
							const payload = {
								id: response.data.data[0].plan_order_id,
								vendor: response.data.data[0].vendor_id,
							};
							PlanningOrder.getTableDetailPo(payload).then(
								(response) => {
									this.planningOrderTable =
										response.data.data;
									this.vendorId =
										response.data.data[0]['vendor_id'];
									this.orgIdNotif =
										response.data.data[0]['org_id'];
									for (var id of this.planningOrderTable) {
										if (
											id.name == 'TO BE PROCESSED' ||
											id.name == 'COMPLETED' ||
											id.name == 'PROCESSED'
										) {
											this.disabledOption[id] = true;
										}
										if (id.note_to_supplier == 'null') {
											id.note_to_supplier = '';
										}
										if (id.remarks == 'null') {
											id.remarks = '';
										}
										if (id.confirm_qty == null) {
											id.confirm_qty = 0;
										}
										if (id.confirm_price == null) {
											id.confirm_price = 0;
										}
										if (id.need_by_date != null) {
											id.need_by_date = this.dateFormat2(
												id.need_by_date
											);
											//
											if (
												id.need_by_date ==
													'Invalid Date' ||
												id.need_by_date ==
													'1-Jan-1970' ||
												id.need_by_date ==
													'1-Jan1970' ||
												id.need_by_date ==
													'NaN undefined NaN'
											) {
												id.need_by_date = '';
											}
									
											id.confirm_ship_date =
											this.dateFormat2(
												new Date(
													id.confirm_ship_date
												)
											);

											if (
												id.confirm_ship_date ==
													'1-Jan-1970' ||
												id.confirm_ship_date ==
													'1-Jan1970' ||
												id.confirm_ship_date ==
													'NaN-undefined-NaN' ||
												id.confirm_ship_date ==
													'1 Jan 1970 '
											) {
												id.confirm_ship_date = '';
											}
											// //
											// //
										} else {
											this.disabledOption[id] = false;
											id.confirm_ship_date = new Date();
										}
										// //
									}
								}
							);

							PlanningOrder.getTermsSupp(payload).then(
								(respond) => {
									let getDistinctLine = null;
									let newSetArray = [];
									respond.data.data.forEach((element) => {
										if (
											getDistinctLine !==
											element.plan_order_line_id
										) {
											newSetArray.push(element);
											getDistinctLine =
												element.plan_order_line_id;
										}
									});
									this.termsTable = [...new Set(newSetArray)];

									for (let id of this.termsTable) {
										//
										if (id.need_by_date != null) {
											id.need_by_date = new Date(
												id.need_by_date
											);
											//
											if (
												id.need_by_date ==
												'Invalid Date'
											) {
												id.need_by_date = '';
											}
											// id.confirm_ship_date = new Date(id.confirm_ship_date)
											id.confirm_ship_date =
												this.dateFormat2(
													new Date(
														id.confirm_ship_date
													)
												);
											if (
												id.confirm_ship_date ==
													'1-Jan-1970' ||
												id.confirm_ship_date ==
													'1-Jan1970' ||
												id.confirm_ship_date ==
													'NaN-undefined-NaN'
											) {
												id.confirm_ship_date = '';
											}
											// //
											// //
										} else {
											id.confirm_ship_date = new Date();
										}

										// //
									}
									// let date = new Date(response.data.data.confirm_ship_date)
									//
								}
							);
						}
					});
				}
			});
		}
		this.getLocation();
		this.getPoType();
		// this.getUom()
		// this.getFreight()
		this.getTablePoNumber();
		this.getCurrencies();
		// this.getTop();
		// this.getIncoterm()
	},
	watch: {
		selectedPo() {
			for (var id of this.selectedPo) {
				//
				if (
					this.selectedPo == [] ||
					id.name == 'TO BE PROCESSED' ||
					id.name == 'PROCESSED'
				) {
					this.button = true;
				} else {
					this.button = false;
				}
			}
		},
	},
	mounted() {
		let payload = sessionStorage.companydb;
		PlanningOrder.getAllCurrencies(payload).then((value) => {
			this.currenciesList = value.data.data;
		});
	},
	computed: {
		// respondDate(){
		//     const date = new Date()
		//     date.setDate(date.respondBy()+ 1)
		//     return date
		// }
	},
	methods: {
		onPage(event) {
			//
			this.page = event.page * event.rows;
		},
		formatCurrency(value) {
			if (value) return value.toLocaleString('id-ID');
			return;
		},
		pad2(n) {
			return (n < 10 ? '0' : '') + n;
		},
		dateFormat2(d) {
			var monthShortNames = [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec',
			];
			var t = new Date(d);
			return (
				t.getDate() +
				' ' +
				monthShortNames[t.getMonth()] +
				' ' +
				t.getFullYear() +
				' '
			);
		},
		formatDateHeader(date) {
			var month = this.pad2(date.getMonth() + 1); //months (0-11)
			var day = this.pad2(date.getDate()); //day (1-31)
			var year = date.getFullYear();
			var combinedNeedDate = new Date(month + '/' + day + '/' + year);
			let formattedDate = [];
			//
			formattedDate[0] =
				combinedNeedDate.getDate() +
				'-' +
				combinedNeedDate.toString().substr(4, 3) +
				'-' +
				combinedNeedDate.getFullYear().toString().replace('-', '');
			formattedDate[1] =
				combinedNeedDate.getMonth() +
				1 +
				'/' +
				combinedNeedDate.getDate() +
				'/' +
				combinedNeedDate.getFullYear();
			return formattedDate;
		},
		formatDate(date) {
			var month = this.pad2(date.getMonth() + 1); //months (0-11)
			var day = this.pad2(date.getDate()); //day (1-31)
			var year = date.getFullYear();
			var combinedNeedDate = new Date(month + '/' + day + '/' + year);
			let formattedDate = [];
			//
			formattedDate[0] =
				combinedNeedDate.getDate() +
				'-' +
				combinedNeedDate.toString().substr(4, 3) +
				'' +
				combinedNeedDate.getFullYear().toString().replace('-', '');
			formattedDate[1] =
				combinedNeedDate.getMonth() +
				1 +
				'/' +
				combinedNeedDate.getDate() +
				'/' +
				combinedNeedDate.getFullYear();
			return formattedDate;
		},
		AllTheSame(array) {
			var first = array[0];
			return array.every(function (element) {
				return element.name === first.name;
			});
		},
		formatDateSend(date) {
			var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();

			if (month.length < 2) month = '0' + month;
			if (day.length < 2) day = '0' + day;

			return [year, month, day].join('-');
		},
		formatDateDexa(date) {
			var date = new Date(date);
			var respondDate =
				('00' + (date.getMonth() + 1)).slice(-2) +
				'/' +
				('00' + date.getDate()).slice(-2) +
				'/' +
				date.getFullYear() +
				' ' +
				('00' + date.getHours()).slice(-2) +
				':' +
				('00' + date.getMinutes()).slice(-2) +
				':' +
				('00' + date.getSeconds()).slice(-2);
			return respondDate.replace('-', '');
		},
		getLocation() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					PlanningOrder.getLocation().then((response) => {
						// //
						this.isiLocation = response.data.data;
					});
				}
			});
		},

		searchLocation(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredLocation = [...this.isiLocation];
				} else {
					this.filteredLocation = this.isiLocation.filter((item) => {
						return item.ship_to_desc
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		getPoType() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					PlanningOrder.getPoType().then((response) => {
						this.isiPoType = response.data.data;
					});
				}
			});
		},
		getFreight() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					PlanningOrder.getFreight().then((response) => {
						this.isiFreight = response.data.data;
					});
				}
			});
		},
		getTop() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					PlanningOrder.getTop().then((response) => {
						this.isiTop = response.data.data;
					});
				}
			});
		},
		getCurrencies(){
			CreatePoService.getAllCurrencies(
				sessionStorage.companydb
			).then((value) => {
				this.currenciesList = value.data.data;
			});
		},
		getIncoterm() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					if (sessionStorage.companydb !== null) {
						const payload = sessionStorage.companydb;
						//
						PlanningOrder.getIncoterm(payload).then((response) => {
							this.isiIncoterm = response.data.data;
							//
						});
					}
				}
			});
		},
		getTablePoNumber() {
			// AuthService.whoAmi().then(response => {
			//     if (response === 200 ){
			PlanningOrder.getTablePoNumber().then((response) => {
				this.tablepopuppo = response.data.data;
				// //
			});
			//     }
			// })
		},
		searchPoType(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPoType = [...this.isiPoType];
				} else {
					this.filteredPoType = this.isiPoType.filter((item) => {
						return item.name
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		getUom() {
			AuthService.whoAmi().then((response) => {
				if (response.status === 200) {
					PlanningOrder.getUom().then((response) => {
						this.isiUom = response.data.data;
						//
					});
				}
			});
		},
		changeStatusHold() {
			if (
				this.planningOrderStatus !== 'H' ||
				this.planningOrderStatus !== 'HOLD'
			) {
				this.changeHold = true;
				this.changeUnhold = false;
				this.submitpo = false;
				let payload = { status: 'H', id: this.idValue };
				PlanningOrder.changeStatus(payload).then((response) => {
					//
					this.$toast.add({
						severity: 'success',
						summary: 'Status Change',
						detail: 'Change Status to Hold',
						life: 3000,
					});
					this.planningOrderStatus = 'HOLD';
				});
			}
		},
		changeStatusUnhold() {
			if (
				this.planningOrderStatus === 'H' ||
				this.planningOrderStatus === 'HOLD'
			) {
				this.changeUnhold = true;
				this.changeHold = false;
				this.submitpo = true;
				let payload = { status: 'C', id: this.idValue };
				PlanningOrder.changeStatus(payload).then((response) => {
					//
					this.$toast.add({
						severity: 'success',
						summary: 'Status Change',
						detail: 'Change Status to Confirmed',
						life: 3000,
					});
					this.planningOrderStatus = 'CONFIRMED';
				});
			}
		},
		searchPopUpPo(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredPopUpPo = [...this.tablepopuppo];
				} else {
					this.filteredPopUpPo = this.tablepopuppo.filter((item) => {
						return item.segment1
							.toLowerCase()
							.match(event.query.toLowerCase());
					});
				}
			}, 250);
		},
		onRowSelect(event) {
			// //
			if (
				event.data.name == 'TO BE PROCESSED' ||
				event.data.name == 'PROCESSED' || event.data.name == 'CANCELLED'
			) {
				const index = this.selectedPo.findIndex(
					(something) =>
						something.name === 'TO BE PROCESSED' ||
						something.name === 'PROCESSED' || something.name == 'CANCELLED'
				);
				this.selectedPo.splice(index, 1);
			}
		},
		openResponsive() {
			this.displayResponsive = true;
		},
		closeResponsive() {
			this.displayResponsive = false;
			this.popUpPo = null;
		},
		submitResponsive() {
			this.displayResponsive = false;
			this.poNumber = this.popUpPo.segment1;
			//
		},
		openPo() {
			//
			this.changeTable = false;
		},
		openTerms() {
			//
			this.changeTable = true;
		},
		back() {
			this.$router.push({ name: 'planningOrder' });
		},
		save() {
			if (this.selectedPo == null || this.selectedPo.length == 0) {
				this.displayPilih = true;
			} else {
				AuthService.whoAmi().then((response) => {
					// //
					// for (var id of this.selectedPo) {
					// //
					var date = this.formatDateDexa(new Date());
					var lopez = {
						last_updated_by: response.data.data.id,
						last_updated_date: date,
					};
					var dateHeader = this.formatDateDexa(
						new Date(this.respondDate)
					);
					var something = [];
					this.selectedPo.forEach((element) => {
						const index = this.planningOrderTable.findIndex(
							(something) =>
								something.plan_order_line_id ===
								element.plan_order_line_id
						);
						this.something = Object.assign(
							this.planningOrderTable[index],
							lopez
						);
						something.push(this.something);
						// //
					});
					var statusHeader;
					var checkPO = this.AllTheSame(this.planningOrderTable);
					if (checkPO) {
						this.planningOrderTable.forEach((element) => {
							if (element.name == 'PROCESSED') {
								statusHeader = 'A';
							}
							if (element.name == 'TO BE PROCESSED') {
								statusHeader = 'C';
							}
							if (element.name == 'NEW') {
								statusHeader = '';
							} else {
								statusHeader = 'D';
							}
						});
					} else if (!checkPO) {
						statusHeader = '';
					}
					let header = {
						id: this.idValue,
						date: dateHeader,
						status: statusHeader,
						last_updated_by: response.data.data.id,
						response_by: this.respondBy,
						last_updated_date: date,
					};
					let payload = {
						isi: something,
						header: header,
					};
					for (var id of payload.isi) {
						if (
							id.confirm_ship_date == null ||
							id.confirm_ship_date.length == 0
						) {
							this.displayConfirm = true;
						} else if (id.remarks == null) {
							id.remarks = '';
						} else {
							id.confirm_ship_date = this.formatDateDexa(
								new Date(id.confirm_ship_date)
							);
							id.need_by_date = this.formatDateDexa(
								new Date(id.need_by_date)
							);
							this.auth = false;
						}
					}
					if (!this.displayConfirm) {
						this.$toast.add({
							severity: 'success',
							summary: 'Save Data',
							detail: 'Data has been saved',
							life: 3000,
						});
						//
						PlanningOrder.saveOptionSupp(payload).then(
							(response) => {
								//
							}
						);

						for (var id of payload.isi) {
							id.confirm_ship_date = new Date(
								id.confirm_ship_date
							);
						}
						sessionStorage.removeItem('tableSession');
						this.selectedPo = [];
						// //
					}
				});
			}
			// //
		},
		// 	onUpdate() {
		//  this.$refs.table.refresh();
		// }
		async confirm(status) {
			if (this.selectedPo == null || this.selectedPo.length == 0) {
				this.displayPilih = true;
			} else {
					this.displayQuantity = false;
					AuthService.whoAmi().then(async (response) => {
					this.progress = true;
					// //
					var date = this.formatDateDexa(new Date());
					var lopez = {
						last_updated_by: response.data.data.id,
						last_updated_date: date,
						status: status,
						response_by: this.respondBy,
						plan_order_id: parseInt(this.idValue),
						suppliername: this.vendorname,
						plan_order_num: this.planningOrderNum,
						plan_order_date: this.planningOrderDate.toUpperCase(),
						response_date: this.respondDate.toUpperCase(),
						emails: this.recipientMail,
					};
					var something = [];
					this.selectedPo.forEach((element) => {
						const index = this.planningOrderTable.findIndex(
							(something) =>
								something.plan_order_line_id ===
								element.plan_order_line_id
						);
						this.something = Object.assign(
							this.planningOrderTable[index],
							lopez
						);
						something.push(this.something);
					});
					var dateHeader = this.formatDateDexa(
						new Date(this.respondDate)
					);
					var statusHeader;
					var checkPO = this.AllTheSame(this.planningOrderTable);
					//
					if (checkPO) {
						this.planningOrderTable.forEach((element) => {
							//
							if (element.name == 'PROCESSED') {
								statusHeader = 'A';
							}
							if (element.name == 'TO BE PROCESSED') {
								statusHeader = 'C';
							} else {
								statusHeader = 'D';
							}
						});
					} else if (!checkPO) {
						statusHeader = '';
					}
					let header = {
						id: this.idValue,
						date: dateHeader,
						status: statusHeader,
						last_updated_by: response.data.data.id,
						last_updated_date: dateHeader,
						username: response.data.data.userid,
					};
					let payload = {
						isi: something,
						header: header,
						module: 'PlannoToPur',
						idUser: sessionStorage.getItem('idUser'),
						companyname: this.company,
						sendpodata: null,
						idmodule: '1',
						sbjmessage:
							'Planning Order #' +
							this.planningOrderNum +
							' has been confirmed by ',
						// receiveddate: new Date(),
						recipient: null,
						sender: this.sender,
						status: 'Close',
						notif_type: 'Purchasing',
						dbInstance: null,
						orgIdComp: null,
						usersenderid: this.usersenderid,
					};

					console.log(payload.isi,"PAYIS")

					for (var id of payload.isi) {
						if (
							id.confirm_ship_date == null ||
							id.confirm_ship_date.length == 0
						) {
							this.displayConfirm = true;
							this.progress = false;
						} else if (
							id.confirm_price == 0 ||
							id.confirm_price == null ||
							id.confirm_qty == 0 ||
							id.plan_qty == null
						) {
							this.displayQuantity = true;
							this.progress = false;
						}else if(
							id.rate_currency == null || 
							id.deal_currency == null || 
							id.rate_currency == 0
						){
							console.log("MASUK SINI", id)
							this.displayCurrency = true;
							this.progress = false;
						}
					}

					if (!this.displayConfirm && !this.displayQuantity && !this.displayCurrency) {
						for (var id of payload.isi) {
							id.confirm_ship_date = this.formatDateDexa(
								new Date(id.confirm_ship_date)
							);
							id.need_by_date = this.formatDateDexa(
								new Date(id.need_by_date)
							);
							
						}
						const value = await PlanningOrder.confirmSupp(payload);

						if(value.status == 208){
							this.progress = false;
							Swal.fire({
								title: "Failed To Submit",
								text: value.data.message,
								icon: "error", 
								showConfirmButton: false, 
								timer: 2000 
							}).then(() => {
								location.reload(); 
							});

							return;
						}


						if (value.status === 200) {
							this.progress = false;
							this.selectedPo = [];
							this.$toast.add({
								severity: 'success',
								summary: 'Status Change',
								detail: 'Status Change',
								life: 3000,
							});
							for (var id of payload.isi) { 
								id.name = 'TO BE PROCESSED';
							}
						} else {
							console.log('else status', value)
							this.progress = false;
							this.selectedPo = [];
							this.$toast.add({
								severity: 'error',
								summary: 'Error Confirm',
								detail: 'Send Mail Failed',
								life: 3000,
							});
						}

						for (var id of payload.isi) {
							id.confirm_ship_date = new Date(
								id.confirm_ship_date
							);
						}
						// KIRIM NOTIFIKASI KE DASHBOARD
						// let emailPayload = payload.isi
						// await this.processEmail(emailPayload)
					}
				}).catch(e=> {
					console.log('error catch', e)
					this.progress = false;
					this.selectedPo = [];
					this.$toast.add({
						severity: 'error',
						summary: 'Error Confirm',
						detail: 'Send Mail Failed',
						life: 3000,
					});
				});
			}
		},

		async processEmail(emailPayload) {
			const data = {
				module: 'PlannoToPur',
				idUser: sessionStorage.getItem('idUser'),
				companyname: this.company,
				sendpodata: emailPayload,
			};
			const respond = await PlanningOrder.getRecipientMail(data);

			this.dbInstance = respond.data.data[0][0]['DB_INSTANCE'];
			var tampungEmail = [];
			var getOrgId = null;
			var suppliername = null;
			respond.data.data[0].forEach((element) => {
				tampungEmail.push(element.targetemailgroup);
				if (getOrgId === null) {
					getOrgId = element.id;
					suppliername = element.vendorname;
				}
			});
			this.recipientMail = tampungEmail
				.toString()
				.replace(/"/g, '')
				.replace(/[\[\]']+/g, '')
				.replace(/ /g, '')
				.replace(/,/g, ', ');
			this.vendorname = suppliername;
			this.orgIdNotif = getOrgId;
			//
			//
			//
			if (respond.status === 200) {
				// KIRIM NOTIFIKASI KE DASHBOARD
				const payload1 = {
					idmodule: '1',
					sbjmessage:
						'Planning Order #' +
						this.planningOrderNum +
						' has been confirmed by ' +
						this.vendorname,
					// receiveddate: new Date(),
					status: 'Close',
					notif_type: 'Purchasing',
					sender: this.sender,
					recipient: this.recipientMail,
					dbInstance: this.dbInstance,
					orgIdComp: this.orgIdNotif,
					usersenderid: this.vendorId,
					// userreceiveid: this.usersenderid,
				};

				const value = await PlanningOrder.sendPONotifSupp(payload1);
				if (value.status === 200) {
					this.recipientMail = [];
					this.selectedPo = [];
					sessionStorage.removeItem('tableSession');
					window.alert('Purchasing Successfully Notified');
					// console.clear();
				}
			}
		},
		printPage() {
			let url = UrlTab() + "/printposupp"
			let printUrl = url;
			window.open(printUrl, 'Print', 'height=720,width=1280');
		},
	},
};
</script>
